.wysiwyg {
  > *:first-child {
    margin-top: 0;
  }

  * + * {
    margin-top: 1.5em;
  }

  p {
    @include font-size(16px, 1.5);
  }
}

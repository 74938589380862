/*
 * Mixins
 * ——————
 */
$screen-xs:480px;
$screen-sm:800px;
$screen-md:960px;
$screen-lg:1170px;

//fontawesome icon
/*
	Usage
	
	.foo:before {
	    @include icon(pencil);
	}
*/
@mixin icon($icon) {
    @include fa-icon;
    @extend .fa;
    @extend .fa-#{$icon}:before;
}



@mixin container($width: 1170px) {
  width: 90%;
  max-width: $width;
  margin-left: auto;
  margin-right: auto;
}

@mixin clearfix {
  &:before,
  &:after {
    content: ' ';
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin font-size($size, $line-height: 1.4) {
  font-size: $size;
  font-size: ($size / 1px) / 10 + rem;
  line-height: $line-height;
}

/*
 * Maintain Aspect Ratio
 * Source: https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/
 */
@mixin img-ratio($width, $height) {
  position: relative;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > .image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

//hidden text but visible to screen readers
.text_hidden {
	position:absolute;
	left:-10000px;
	top:auto;
	width:1px;
	height:1px;
	overflow:hidden;
}

// Responsive image
//
// Keep images from scaling beyond the width of their parents.
@mixin img-responsive($display: block) {
  display: $display;
  max-width: 100%; // Part 1: Set a maximum relative to the parent
  height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}


.img-responsive{
	@include img-responsive;
}


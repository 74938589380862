
a.navbar-brand{
	text-transform: uppercase;
	@include font-size(25px, .6);
	font-family: $font2;
	font-weight: normal;
	color: $color1;
	outline: none;
	padding:.5em 0;
	
	&:hover,
	&:focus {
		color:$color1;
		outline:0;
	}
	@media (min-width: 400px) {
		@include font-size(26px, .6);
	}
	
	@media (min-width: 600px) {
		@include font-size(28px, .6);
	}
	&.logo-img{
		background-size:contain;
		background-repeat:no-repeat;
		background-position: center center;
		height:34px;
		width:140px;
		min-width:140px;
		padding:0;
		margin:5px 0;
		span{display:none;}
		@include media-breakpoint-up(sm){
			min-width:150px;
			width:150px;
			height:44px;
			margin:0;
		}
	}
}

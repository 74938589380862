body{
	background:#f1f1f1;
}

body.page:not(.home){
	nav.navbar{display:none;}
	.site-wrap{
		padding:15px;
		main.main{
			width:100%;
		}
		article.type-page{
			width:auto;
			margin-bottom:3em;
			& > .holder{
				@include make-container();
				max-width:940px;
				margin:0 auto;
				.page-title h1{
					text-align:center;
					font-size:2.4em;
					margin-bottom:1em;
				}
				div.gallery{
					.gallery-item{
						img{
							@extend .img-responsive;
						}
					}
				}
			}
		}
	}
}



// Base
//@import 'base/...';

//booptstrap
@import 'bootstrap/bootstrap';

//fontawesome
@import 'font-awesome/font-awesome';

//swiper
@import 'swiper/swiper.css';

//baguette box lightbox plugin
@import 'components/baguetteBox.css';

// Config
@import 'config/normalize';
@import 'config/colors';
@import 'config/fonts';
@import 'config/mixins';

// Layout
@import 'layout/layout';
@import 'layout/container';
@import 'layout/forms';

// Components
@import 'components/ui/button';
@import 'components/ui/title-banner';
@import 'components/ui/wysiwyg';
@import 'components/ui/back-to-top';

@import 'components/page/page';
@import 'components/blog/post';
@import 'components/blog/index';
@import 'components/blog/comments';
@import 'components/attachment';


@import 'components/header/header';
@import 'components/header/header-logo';
@import 'components/header/navigation';
@import 'components/header/hamburger';

@import 'components/footer/footer';

@import 'components/sections/section';
@import 'components/sections/intro';
@import 'components/sections/gallery';
@import 'components/sections/about-us';
@import 'components/sections/partners';


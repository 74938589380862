/*
 * Layout
 * ——————
 */

html,
body {
	position:relative;
	height: 100%;
	font-size: 62.5%; // using rems in there
	font-smoothing: subpixel-antialiased;
}

body {
  @include font-size(14px);
  font-family: $font1;
  /*
   * SVG Symbols needs to be hidden
   */
  > svg:first-of-type {
    display: none;
  }
}

/*
 * Border-box default
 */
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

a {
  color: $black;
  text-decoration: none;
}

img {
  max-width: 100%;
}

.site-wrap {
  position: relative;
}

.section {
	border-bottom:1px solid #ddd;
	padding:3em 0;
	@include media-breakpoint-up(sm){
		padding:5em 0;
	}
			
		
	.section__content{
		@include make-container();
		@include font-size(16px, 1.6);
		max-width:960px;
		h3{
		  @include font-size(30px, 1);
		  font-family:$font2;
		  margin-bottom:1em;
		}
		h4{
			@include font-size(22px, 1.6);
			font-family:$font2;
			margin-bottom:1em;
			color:rgba(0,0,0,0.6);
		}
		.btn{
		  font-size:1em;
		  text-transform: uppercase;
		}
		.img-responsive{
			margin:0 auto;
		}
		p:last-child{
			margin-bottom:0;
		}
		.hellip{color:rgba(0,0,0,0.2);}
		&.collapse-shown,
		.collapse-shown{
			.hellip{display:none;}
		}
		.more-section.collapse.show{
			display:inline;
		}
		@include media-breakpoint-up(sm){
			@include font-size(18px, 1.6);
		}
		.gmap{
			border:4px solid silver;
		}
		.highlight{
			padding:.5em;
			margin-bottom:1em;
			background:rgba(0,0,0,0.05);
			a{
				text-decoration:underline;
			}
		}
	}//section__content
	.section__heading,
	.section__sub-heading {
		@include make-container();
		max-width:960px;
	}
	a.website-link:before{
		@include icon(link);
	}
	&.no-title{
		.section__heading{display:none;}
	}
	&.no-border{
		border:none;
	}
	&.bg-white{
		background:$white;
	}
	&.section-about-us{
		padding-bottom:0;
	}
	&.section-bios{
		.section__content{
			@include font-size(15px, 1.6);
			div.row{
				justify-content: center;
			}
			.bio-col{
				//max-width:430px;
				text-align:justify;
				h3{text-align:center;}
				.website-link{font-weight:bold;}
				img{
					@extend .img-responsive;
					margin:0 auto .5em;
				}
				@include media-breakpoint-down(sm){
					margin-bottom: 2em;
				}
			}
		}
	}
	&.section-participate{
		#participate_form_div{
			max-width:500px;
			padding:1em;
			border-radius:3px;
			//background:$white;
			margin:0 auto;
			.frm_forms{
				margin:0;
				padding:0;
				border:0;
			}
		}
	}
	&.section-where{
		.gmap{
			height:300px;
		}
	}
	&.section-partners{
		.section__content{
			color:rgba(0,0,0,0.6);
		}
	}
	&.section-email-signup{
		.section__content{
			max-width:500px;
			text-align:center;
		}
	}
	&.disabled{
		display:none;
	}
}//.section


.section__heading {
	@include font-size(40px, 1);
	text-align:center;
	margin-top: 0;
	margin-bottom:1em;
	font-family: $font2;
	font-weight: normal;
	letter-spacing: 0.05em;
	text-transform: uppercase;
  	@include media-breakpoint-up(sm){
		@include font-size(50px, 1);
	}
}

.section__sub-heading {
  @include font-size(22px, 1);
  margin-top: 20px;
  padding-bottom: 15px;
  font-family: $font1;
  font-weight: normal;
  border-bottom: 1px solid rgba($white, 0.1);
}

.section__footer{
	text-align:center;
	padding:0 0 1em;
	.section__sub-heading{
		font-family: $font1;
		font-weight: normal;
		text-transform: uppercase;
		font-size:1.5em;
	}
	a{
		color:$blue;
	}
}

.more-toggle{
	display:block;
	font-family:$font2;
	padding:.3em 1em;
	font-size:.9em;
	text-align:center;
	background:rgba(0,0,0,0);
	border-radius:3px;
	text-decoration: none;
	color:rgba(0,0,0,0.2);
	text-transform: uppercase;
	transition:background .2s ease;
	&:not(.collapsed){&:before{@include icon(arrow-up); padding-right:.5em;}}
	&.collapsed{&:after{@include icon(arrow-down);}}
	&:hover{
		text-decoration: none;
		background:rgba(0,0,0,0.05);
	}
	&:focus{
		text-decoration: none;
		background:none;
		color:rgba(0,0,0,0.2);
	}
}
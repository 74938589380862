.footer {
  padding: 60px 0 60px;
  background-color:$white;
  border-top:3px solid $color1;
}

.footer__container {
  	@include make-container();
  	max-width:960px;
  	.footer__content {
	  	.footer__about-contact{
			@include make-row();
			justify-content: center;
			& > div{
				@include make-col-ready();
				@extend .col-sm-4;
				//max-width:300px;
				margin-bottom:2em;
			}
		}
	}
}




.footer__widget--small {
  height: 260px;
}

.footer__widget-area {
  padding: 20px;
}

.footer__logo {
  width: 100%;
  fill: $white;
}

.footer__content {
  
  h3 {
    @include font-size(22px);
    font-family: $font2;
    margin:0 0 .5em;
    text-transform: uppercase;
  }

  p {
    margin: 0;

    + p {
      margin-top: 10px;
    }
  }

  .menu {
    margin-top: 5px;
    margin-bottom: 5px;
    list-style: none;
    padding-left: 0;
  }

  li {
    + li {
      margin-top: 3px;
    }
  }

  a {
    &:hover,
    &:focus {
      text-decoration: underline;
      outline:0;
    }
  }
}



.footer-copyright {
  @include container;
  margin-top:4em;
  text-align:center;
  font-family:$font1;
  font-weight:400;
  font-size:.9em;
}

.footer.footer-subpage{
	.footer-logo{
		text-align:center;
		margin-bottom:2em;
		a img{
			min-width:300px;
			width:300px;
			height:auto;
		}
		a span{
			text-transform: uppercase;
			display:block;
			margin-bottom:.25em;
		}
	}
	.footer__container {
		max-width:660px;	
	}
	.footer__about-contact{
		text-align:center;
	}
}

.navbar-toggler:focus,
.navbar-toggler:active {
    outline: 0;
}

.navbar-toggler{
	border:none;
	top: 50%;
    transform: translateY(-50%);
}
.navbar-toggler span {
   display: block;
   background-color:$color1;
   height: 3px;
   width: 25px;
   margin-top: 3px;
   margin-bottom: 4px;
   transform: rotate(0deg);
   position: relative;
   left: 0;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
   transition: transform .2s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute;
    left: 12px;
    top: 5px;
    transform: rotate(135deg);
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px;
    visibility: hidden;
    background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute;
    left: 12px;
    top: 5px;
    transform: rotate(-135deg);
}


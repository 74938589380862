nav.navbar{
	#navbarMenu{
		justify-content:flex-end;
		@include media-breakpoint-down(sm){
			background:white;
			position: absolute;
			top: 100%;
			width: 100%;
			left: 0px;
			font-size:1.5em;
			border:3px solid $color1;
			border-width: 3px 0;
		}

		
		
		ul.navbar-nav{
			flex:1;
			li{
				display:flex;
				flex-direction: column;
				justify-content: center;
				@include media-breakpoint-down(sm){
					text-align:center;	
				}
				&.active{
					//caret below the nav active link on desktop
					@include media-breakpoint-up(sm){
						position:relative;
						a{
							//text-decoration:underline;
							&::after {
								display: block;
								width: 0;
								height: 0;
								vertical-align: middle;
								content: "";
								border-top: 8px solid;
								border-right: 8px solid transparent;
								border-left: 8px solid transparent;
								position: absolute;
							    top: calc(100% + 3px);
							    left: 50%;
							    transform:translateX(-50%);
							}
						}
					}
				}
			}
		}
		a{
			color:$black;
			padding:.6em 1em;
			font-size: 1.2em;
			font-weight: bold;
			text-transform: uppercase;
			&:hover,
			&:focus,
			&:active{
				opacity:.9;
				text-decoration: none;
			}
		}
	}
}


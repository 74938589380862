body.attachment.single{
	nav.navbar{display:none;}
	.site-wrap{
		padding:15px;
		main.main{
			width:100%;
		}
		article.type-attachment{
			width:auto;
			margin-bottom:3em;
			& > .holder{
				@include make-container();
				max-width:660px;
				margin:0 auto;
				.entry-title{
					text-align:center;
					font-size:2em;
					margin-bottom:1em;
				}
				p.attachment{
					text-align:center;
					margin-bottom:2em;
					a{
						&:hover{
							color:$black;
						}
						img{
							height:auto;
							border:1em solid;
						}
					}
					& + p{
						font-size:1.4em;
					}
				}
			}
		}
	}
}

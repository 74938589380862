nav.posts-navigation{
	max-width:800px;
	margin:2em auto;
	@include clearfix;
	
	.nav-previous,
	.nav-next{
		a{
			font-size:1.3em;
			font-family:$font1;
			text-transform: uppercase;
			color:darken($blue,10%);
			&:hover{
				color:lighten($blue,0%);
				text-decoration:none;
			}
		}
	}
	.nav-previous a{
		float:left;
		&:before{
			content:'< ';
		}
	}
	.nav-next a{
		float:right;
		&:after{
			content:' >';
		}
	}
}

.main{
	& > .alert{
		text-align:center;
		padding:2em 0;
		font-size:1.2em;
		font-family:$font1;
	}
}

.back-to-top {
  position: fixed;
  z-index: 50;
  right:20px;
  bottom: -1000px;
  padding:5px 15px;
  background-color: $white;
  color:$color1;
  text-decoration: none;
  border: none;
  border-radius: 7px;
  opacity: 0;
  font-family:$font2;
  text-align:center;
  text-transform: uppercase;
  transition: all 50ms ease-in-out;

  &.visible {
    opacity: 1;
    bottom: 20px;
    &:hover,
    &:focus,
    &:active {
	  color:$color1;
      opacity: 1;
      text-decoration: none;
    }
    &:active{
	   opacity: 0; 
    }
  }
  span.fa{
	  display:block;
	  font-size:1.2em;
	  margin-bottom:3px;
  }
}

/*
 * Form Elements
 * —————————————
 */


.frm_forms{
	.frm_error_style{
		p:last-child{
			margin:0;
		}
	}
	.frm_form_fields{
		& > fieldset{
			&:last-child{
				padding-bottom:0;
			}
		}
	}
	.frm_message{
		text-align:center;
	}
}
.frm_style_formidable-style.with_frm_style .frm_compact .frm_dropzone.dz-clickable .dz-message, .frm_style_formidable-style.with_frm_style input[type=submit], .frm_style_formidable-style.with_frm_style .frm_submit input[type=button], .frm_style_formidable-style.with_frm_style .frm_submit button, .frm_form_submit_style, .frm_style_formidable-style.with_frm_style.frm_login_form input[type=submit]{
	margin:0;
}


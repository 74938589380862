.title-banner {
  position: relative;

  &.is-plain {
    background-color: $black;
  }
}

.title-banner__bg {
  height: 200px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @media (min-width: 700px) {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.title-banner__container {
  @include container;
  position: relative;

  @media (min-width: 700px) {
    height: 200px;
  }
}

.title-banner__content {
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;

  @media (min-width: 700px) {
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 40px;
    text-align: left;
  }
}

.title-banner__heading {
  @include font-size(22px);
  padding: 5px 10px;
  margin-top: 0;
  font-weight: 200;
  text-transform: uppercase;
  color: $white;
  background-color: $black;

  @media (min-width: 700px) {
    @include font-size(30px);
    margin-top: 10px;
  }
}

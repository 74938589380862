nav.navbar{
	background:transparent;
	padding:0;
	box-shadow:none;
	transition:all .2s ease;
	border-bottom:3px solid transparent;
	& > .holder{
		display:flex;
		@include make-container();
		flex:1;
		max-width:960px;
		width:100%;
	}
	a.navbar-brand{
		transition:all .2s ease;
		opacity:0;
	}
	nav.navbar-nav{
		@include media-breakpoint-up(sm){
			transition:all .2s ease;
			opacity:0;
		}
	}
	&.is-sticky {
	
	}
	&.is-stuck{
		border-bottom:3px solid $color1;
		//box-shadow:0 1px 10px rgba(0,0,0,0.15);
		background:$white;
		a.navbar-brand{
			opacity:1;
		}
		nav.navbar-nav{
			@include media-breakpoint-up(sm){
				opacity:1;
			}
		}
	}
}

.js-menu-overlay-point{
	position:absolute;
	top:51%;
	left:0;
}

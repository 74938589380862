.partners{
	margin-top:2em;
	&.partners-grid{
		@include make-row();
		margin-bottom:0;
		padding:0;
		& > li{
			position:relative;
			@include make-col-ready();
			@include make-col(6);
			list-style-type: none;
			margin-bottom:0px;
			@include media-breakpoint-up(sm){
				@include make-col(3);
			}
			a, span.holder{
				display:block;
				position:relative;
			}
			img{
				filter: grayscale(100%);
			}
			.caption{
				background:white;
				padding:.3em 0;
				position:absolute;
				bottom:0;
				left:2px;
				width:calc(100% - 4px);
				opacity:0;
				display:block;		
				text-align:center;
				font-size:.7em;
				margin-top:.5em;
				transition:all .2s ease;	
				color:$color1;
				text-transform: uppercase;
			}
			&:hover{
				.caption{
					opacity:1;
				}
			}
		}
	}
}
div.col-sm-6 > .partners.partners-grid{
	& > li{
		@include media-breakpoint-up(sm){
			@include make-col(4);
		}
	}
}
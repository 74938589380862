.comment-list ol {
  list-style: none;
}
.comment-form p {
  //@extend .form-group;
}
.comment-form input[type="text"],
.comment-form input[type="email"],
.comment-form input[type="url"],
.comment-form textarea {
  //@extend .form-control;
}
.comment-form input[type="submit"] {
  //@extend .btn;
  //@extend .btn-primary;
}



section.comments{
	//@extend .container;
	margin-top:80px;
	clear:both;
	@media (min-width:$screen-sm){
		padding: 0 15px;
	}
	margin-bottom:4em;
	.comments-header{
		font-size:1em;
		h2,.title{
			font-family:$font1;
			text-transform:uppercase;
			text-align:center;
			font-size:2em;
			margin:0;
			padding:.25em .5em;
			line-height:1.6em;
			&:before{
				font-size:1.3em;
				display:inline-block;
				padding-right:.5em;
			}
		}	
	}//.comments-header
	.comment-list{
		padding:0;
		.comment{
			border-bottom:1px solid rgba(0,0,0,0.1);
			padding:2em 0;
			list-style-type: none;

			&:last-child{
				border-bottom:none;
			}
			a{
				text-decoration: none;
			}
			.comment-body{
				margin:0 -15px;
				@include clearfix;
				max-width:800px;
				a{
					color:$blue;
					&:hover{
						color:lighten($blue,10%);
					}
				}
				div.avatar{
					width:10%;
					float:left;
					padding:0 15px;
					padding:0;
					text-align:center;
					img{
						@include img-responsive;
						border-radius:50px;
						border:4px solid white;
						margin:auto;
					}
				}
				.comment-info{
					width:90%;
					float:left;
					padding:0 15px;
					.comment-author{
						//font-family:$font2;
						font-weight:700;
						float:left;
						cite{font-style: normal;}
						a{
							//color:$color-news;
						}
					}
					.comment-meta{
						float:right;
						font-family:$font1;
						font-weight:400;
						font-size:.75em;
					}
					.comment-text{
						clear:both;
						p:last-child{
							margin-bottom:0;
						}
					}
					.reply{
						text-align:right;
						font-family:$font1;
						font-weight:700;
						text-transform: uppercase;
						a{
							color:rgba(0,0,0,0.4);
							&:before{
								display:inline-block;
								font-size:1.2em;
								padding-right:.5em;
							}
							&:hover{
								color:$blue;
							}
						}
					}
				}
			}
			ol.children{
				top: 2em;
				position: relative;
				.comment{
					border-top:1px solid rgba(0,0,0,0.1);
					border-bottom:none;
					font-size:1em;
				}
			}
		}
	}//.comments-list
	.comment .comment-respond{
		padding-top:2em;
	}
	.comment-respond{
		.comment-notes{
			font-size:.9em;
		}
		.comment-reply-title{
			font-size:2em;
			font-family:$font1;
			font-weight:700;
			text-transform: uppercase;
			text-align:center;
			position:relative;
		}
		.comment-form-url{
			display:none;
		}
		& > form{
			margin:2em auto 0;
			max-width:750px;
		}
	    a#cancel-comment-reply-link{
		    //@include button($darkblue);
		    display: block;
			text-align: right;
			font-size:.5em;
			position:absolute;
			top:-2em;
			right:0;
			&:hover{
				//@include button-hover($white,$darkblue);
			}
		}
		label{
			text-transform: uppercase;
			font-family:$font1;
			display:block;
		}
		
		textarea{
			border:2px solid $darkblue;
			border-radius:4px;
			width:100%;
			padding:.5em;
			&:focus{
				border-color:$blue;
				outline:0;
				box-shadow:none;
			}
		}
		input[type=text],
		input[type=email]{
			border:2px solid $darkblue;
			border-radius:2px;
			padding:.2em .5em;
			&:focus{
				border-color:$blue;
				outline:0;
			}
		}
		.comment-form-comment{
			label{display:none;}
		}
		.comment-form input[type="submit"] {
		  //@include button($darkblue);
		  &:hover{
			 //@include button-hover($white,$darkblue); 
		  }
		}
		p.logged-in-as,
		p.comment-notes{
			text-align:center;
		}

	}//.comment-respond
}//section.comments
.btn.btn-primary{
	background:$color1;
	border-color:$color1;
	&:hover{
		background:lighten($color1,10%);
		border-color:lighten($color1,10%);
	}
	&:focus{
		box-shadow:none;
		color:$white;
	}
}
article.post{
	margin:0 auto 3em;
	padding:0 15px;
	max-width:800px;
	@include clearfix;
	@media (min-width:$screen-sm){
		padding:0;
	}
	header{
		margin-bottom:.5em;
		.entry-title{
			margin-top:0;
			margin-bottom:0;
			font-family: $font1;
			font-weight: bold;
			text-transform: uppercase;
			color: $darkblue;
			font-size:2.6em;
			a{
				text-decoration:none;
				color:$darkblue;
				&:hover{
					color:darken($blue,10%);
				}
			}
		}//.entry-title
		.entry-meta{
			font-size:1em;
			letter-spacing:1px;
			font-weight:400;
			font-family:$font1;
			text-transform: uppercase;
			color:rgba($darkblue,.5);
			& > *{
				& + *{
					&:before{
						color:rgba($darkblue,.3);
						content:' • ';
						display:inline-block;
						padding:0 .35em;
					}
				}
			}
			time{
				display:inline-block;
			}
			.categories{
				display:inline-block;
			}
			a{
				color:rgba($darkblue,.6);
				text-decoration: none;
				
				&:hover{
					color:$blue;	
				}
			}
			.author{
				display:none;
				&.author-1{display:none}	
			}
			p{margin:0;}
		}//.entry-meta
		
	}//header
	.entry-content{
		line-height:1.6em;
		font-size:1.2em;
		a{
			color:darken($blue,10%);
			&:hover{
				color:$blue;
			}
		}
		img{
			&.aligncenter{
				display:block;
				margin:0 auto;
			}
			&.alignleft{
				float:left;
			}
			&.alignright{
				float:right;
			}
		}
	}//.content

	&.post-excerpt{
		margin-bottom:3em;
		header{
			.entry-title{
				font-size:2.1em;
			}
		}
		.entry-content{
			font-size:1em;
		}
		&.has-post-thumbnail{
			& > .holder{
				@media (min-width:500px){
					margin:0 -15px;
				}
			}
			.image{
				margin-bottom:1em;
				@media (min-width:500px){
					width:33.33%;
					float:left;
					padding:0 15px;
					margin-bottom:0;
				}
				img{
					max-width:100%;
					height:auto;
					display:block;
					margin:0;
					border:2px solid rgba(0,0,0,0.3);
				}
			}
			.content{
				@media (min-width:500px){
					width:66.666%;
					float:left;
					padding:0 15px;
					margin-bottom:0;
				}			
			}
		}//&.has-post-thumbnail
	}//&.post-excerpt
}//article.post

	xxarticle.post,
	xxarticle.page{
		
		&.post-excerpt{
			margin-bottom:3em;
			&.has-post-thumbnail{
				& > .holder{
					//@include make-row();
				}
				.image{
					margin-bottom:1em;
					@media (min-width:400px){
						//@include make-xs-column(3);
						margin-bottom:0;
					}
					@media (min-width:500px){
						//@include make-xs-column(4);
					}
					//@include make-sm-column(4);
					img{
						//@extend .img-responsive;
					}
				}
				.content{
					@media (min-width:400px){
						//@include make-xs-column(9);
					}
					@media (min-width:500px){
						//@include make-xs-column(8);
					}
					//@include make-sm-column(8);
					@media (min-width:$screen-sm){
						.entry-summary{}
					}
					
				}
			}//&.has-post-thumbnail
			.content{
				@media (min-width:$screen-sm){
					.entry-summary{
						font-size:.95em;
					}
				}
			}
			&.category-news,
			&.category-event{
				position:relative;
				padding-top: 1em;
				&:before{
					display: inline-block;
					border:3px solid $black;
					padding: 0.2em 1.5em;
					
					position:absolute;
					top:-1em;
					left:15px;	
					//font-family: $font2;
					font-size: .55em;
					font-weight: 700;
					text-transform: uppercase;
					//color: $color-bgdark;
					letter-spacing: .5px;
					@media (min-width:800px){
						left:0;
					}
				}
			}
			&.category-news{
				&:before{
					content:'News';
					//background:$color-news;
				}
			}
			&.category-event{
				&:before{
					content:'Event';
					//background:$color-events;
				}
			}
		}//&.post-excerpt
	}//article.post
.swiper-container {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    .caption-tooltip, 
    .caption-lightbox{
	    display:none;
    }
    .swiper-slide{
	    a.thumbnail-link{
		    display:block;
	    }
	    .swiper-lazy-preloader{
		    position:static;
		    margin:1em auto;
	    }
    }
    .swiper-pagination{
		position:static;
		margin-top:30px;
		.swiper-pagination-bullet{
			height:12px;
			width:12px;
			&.swiper-pagination-bullet-active{
				background-color:$black;
			}
		}
		@media (max-width:767px){
			display:none;
		}

	}
	.swiper-nav-buttons{
		display:none;
		position:relative;
		margin:10px auto 0;
		text-align:center;
		.swiper-button-next,
		.swiper-button-prev{
			background:none;
			height:auto;
			width:auto;
			font-size:1.5em;
			position:static;
			display:inline-block;
			&.swiper-button-disabled{
				color:rgba(0,0,0,0.2);
			}
		}
		.swiper-button-next{
			padding-left:.5em;
			&:before{
				@include icon(arrow-right);
			}
		}
		.swiper-button-prev{
			padding-right:.51em;
			&:before{
				@include icon(arrow-left);
			}
		}
		@media (max-width:767px){
			display:block;
		}
	}
}

.tippy-tooltip.swiper-theme {
  background-color: white;
  border: 2px solid $black;
  font-size:1.5em;
  color:$black;
  .tippy-arrow{
	  bottom:-9px;
  }
  .caption-tooltip{
	  text-align:center;
	  max-width:300px;
	  .title{
		  font-weight:bold;
		  font-size:1em;
		  display:block;
	  }
	  .subtitle{
		  font-style: italic;
		  font-size:.8em;
		  display:block;
	  }
	  .description{
	  	display:none;
	  }
	  
  }
}
//lightbox caption
#baguetteBox-overlay {
	.full-image figcaption{
		padding:10px;
		background:rgba(0,0,0,.7);
		color:white;
		font-size:.8em;
		@media (min-height:800px){
			font-size:1em;
			padding:15px;
		}
		.title{
			display:block;
			font-size:2em;
			font-weight:bold;
			font-family:$font2;
		}
		.subtitle{
			display:block;
			font-style: italic;
		}
		.description{
			display:block;
			text-align:left;
			margin:.5em auto 0 auto;
			max-width:790px;
			padding:10px;
			overflow-y: auto;
			@media (min-height:800px){
				max-height: 220px;
			}
		}
		.hide{
			cursor:pointer;
			display:block;
			position:absolute;
			bottom:100%;
			right:0;
			background:$white;
			color:$black;
			padding:.5em .5em;
			font-size:.85em;
			text-transform: uppercase;
			&:focus{
				background:$white;
			}
			&:hover{
				background:$yellow;
			}
			&:before{
				@include icon(arrow-down);
				padding-right:.5em;
			}
			&:after{
				content:'hide info';
			}
		}
	}
	&.info-hidden{
		.full-image figcaption{
			top:100%;
			bottom:auto;
			.hide{
				&:after{
					content:'info';
				}
				&:before{
					@include icon(info-circle);
				}
			}
		}
	}
}



ul.gallery-grid{
	margin:0;
	padding:0;
	@include make-row();
	& > li{
		@include make-col-ready();
		@include make-col(6);
		list-style-type: none;
		margin-bottom:30px;
		@include media-breakpoint-up(sm){
			@include make-col(4);
		}
		& > a{
			img{
				//border:.5em solid $color1;
				//box-shadow:0 1px 10px rgba(0,0,0,0.15);
			}
		}
	}
}
.baguetteBox-button{
	&:focus{
		outline:none;
	}
}

body.home{
	.section{
		&.section-intro{
			padding-top:5em;
			padding-bottom:5em;
			.section__content{
				.logo{
					text-align:center;
					max-width:300px;
					margin:0 auto;
					img{
						@extend .img-responsive;
						margin:0 auto;
					}
				}
			}
		}
	}
}